body {
    line-height: 1.4;
    color: #4c4c4c;
    min-width: 350px;
}

/**
 * --- Containers ---
 */

/* stylelint-disable scss/at-extend-no-missing-placeholder */
.container-wide {
    @extend .container-xl;

    @include media-breakpoint-up(xxl) {
        max-width: 1400px;
    }

    &.container-grey {
        background-color: $bg-color-grey;
        margin: 20px auto;
    }

    &.container-grey-light {
        background-color: $bg-color-grey-light;
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;

        > .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.container-wide-grey {
    margin: 20px auto;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $bg-color-grey;
}

.container.main {
    margin-top: 40px;
}

/**
 * --- Header Area ---
 */

.alert-info {
    color: white;
    background-color: $brand-red-dark;
    padding: 10px 0 8px;

    &.alert-info-style-blue {
        background-color: $brand-blue;
    }

    p {
        text-align: center;
        color: white;
        margin: 0;

        a,a:visited,a:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

header {
    .logo {
        margin-top: 36px;
        margin-bottom: 26px;
        margin-left: 0;

        @media (min-width: 1420px) {
            margin-left: 0;
        }

        img {
            width: 144px;
            height: auto;

            @include media-breakpoint-up(md) {
                width: 160px;
            }
        }
    }

    .brandlogos {
        float: right;
        margin-top: 30px;

        img {
            width: 150px;
            height: auto;
        }

        @include media-breakpoint-up(md) {
            img {
                width: 165px;
            }
        }
    }

    .container-nav {
        background-color: $brand-red-dark;
    }
}

img.header-mobile {
    display: none;
}

@media (max-width: 576px) {
    img.header-large {
        display: none;
    }

    img.header-mobile {
        display: block;
    }
}

/**
 * --- Footer Area ---
 */
footer {
    color: #fff;
    margin-top: 50px;
    font-size: 14px;

    .container-wide {
        background-color: $brand-red-dark;
        min-height: 50px;
        padding-top: 18px;

        div.col-left {
            @extend .col-md-8;
            @extend .col-sm-6;
            @extend .col-12;
        }

        div.col-right {
            @extend .col-md-4;
            @extend .col-sm-6;
            @extend .col-12;
            text-align: right;
            font-family: $font-family-cstm-2;
            font-weight: 600;
        }

        @include media-breakpoint-down(sm) {
            div.col-left,
            div.col-right {
                text-align: center;
            }

            div.col-right {
                margin-bottom: 12px;
            }
        }
    }
}

.cookie-consent {
    position: fixed;
    display: none;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 20px 0;
    background-color: rgba(51,51,51,0.9);
    color: #fff;
    z-index: 1001;

    p {
        font-family: $font-family-sans-serif;
        text-align: center;
    }

    a {
        color: #fff;
        text-decoration: underline;

        &.close-cookiebar {
            float: right;
            margin: 0 10px 10px 0;
            text-decoration: none;

            &:hover,&:active,&:focus {
                text-decoration: none;
            }
        }

        &.btn {
            font-family: $font-family-sans-serif;
            font-size: 14px;
            background: #fff;
            border-radius: 0;
            text-decoration: none;
            color: #333;
            padding: 3px 6px;
            margin-left: 16px;
            margin-top: 10px;

            @include media-breakpoint-up(sm) {
                margin-top: inherit;
            }
        }
    }
}

/* stylelint-enable scss/at-extend-no-missing-placeholder */
