/**
 * --- Headlines ---
 */
h1,h2,h3,h4 {
    margin-top: 1.5625rem;
    margin-bottom: 0.75rem;

    a {
        color: $brand-red-dark;

        &:hover,
        &:focus {
            color: $brand-red-dark;
        }
    }
}

h1 {
    font-family: $font-family-cstm-1;
    color: $brand-red-dark;
    text-transform: uppercase;

    @media (max-width: 400px) {
        font-size: 28px;
    }
}

h2, h4 {
    font-family: $font-family-cstm-2;
    color: $brand-red-dark;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

h3 {
    font-weight: bold;
}

.tx-felogin-pi1 {
    h3 {
        font-family: $font-family-cstm-2;
        font-size: 20px;
        font-weight: 600;
        color: $brand-red-dark;
    }
}

// Special header
h3.strike {
    position: relative;
    z-index: 1;

    color: $brand-red-dark;
    text-align: center;
    text-transform: uppercase;

    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    margin: 40px auto 20px auto;
    width: 80%;

    &::before {
        border-top: 0 solid transparent;
        content: '';
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
    }

    span {
        background-color: transparent;
        padding: 0;
        z-index: 2;
    }

    @include media-breakpoint-up(md) {
        &::before {
            border-top: 1px solid $brand-red-dark;
        }

        span {
            background-color: #fff;
            padding: 0 15px;
        }
    }
}

.container-wide-grey {
    h3.strike {
        span {
            background-color: $bg-color-grey;
        }
    }
}

// Subheader
p.lead {
    font-family: $font-family-cstm-2;
    color: $brand-red-dark;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

p.text-right {
    text-align: right;
}

a {
    &.btn-secondary {
        padding: 10px 12px;
        color: #fff;
        background-color: $brand-blue-light;
        border-color: $brand-blue-light;
        border-radius: 4px;

        &:hover {
            color: #fff;
            background-color: $brand-blue;
            border-color: $brand-blue;
        }
    }
}

// Content lists
.frame-type-text,
.frame-type-textpic {
    ul {
        margin-left: 0;
        padding-left: 18px;
    }
}

// Manual menus (lists)
.frame-type-menu_pages,
.frame-type-menu_subpages {
    ul {
        list-style: none;
        padding: 0;

        li {
            padding-left: 1.3em;
            padding-bottom: 6px;

            &::before {
                /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                font-family: FontAwesome;
                font-size: 12px;
                color: $brand-blue-light;
                content: '\f054';
                display: inline-block;
                margin-left: -1.3em;
                width: 1.3em;
            }
        }
    }
}
