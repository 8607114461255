// -----------------------------------------
// bootstrap variables

// Options (366)
$enable-dark-mode: false;

// Links (449)
$link-decoration: none;

// Grid columns (515)
$grid-gutter-width: 30px;

// Components (528)
$border-radius: 0;

// Typography (600)
$font-family-sans-serif: "Source Sans 3", Verdana, sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.62;
$h1-font-size: 1.875rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1rem;
$h4-font-size: 0.875rem;

// Forms (866)
$input-border-radius: 0;

// -----------------------------------------
// custom variables

$gray: #999;
$gray-font: #757575;
$gray-darker: #212121;
$gray-light: #f1f1f1;
$font-family-cstm-1: "Source Sans 3", Verdana, sans-serif;
$font-family-cstm-2: "Source Sans 3", Verdana, sans-serif;
$font-family-script: "Source Sans 3", Verdana, sans-serif;
$font-size-caption: 11px;
$headings-font-weight: 300;
$brand-red-dark: #e40028;
$primary: $brand-red-dark;
$brand-blue-light: #009fe3;
$secondary: $brand-blue-light;
$brand-blue: #3e94ce;
$brand-white: #fff;
$brand-black: #000;
$bg-color-grey: #e6e6e6;
$bg-color-grey-light: #f7f7f7;
$bg-color-grey-box: #f1f1f1;
$text-color: #4c4c4c;
$link-color: $brand-blue-light;
$link-hover-color: $brand-blue-light;
$link-hover-decoration: none;

// Font Awesome

$fa-font-path: '../fonts';
