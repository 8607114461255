/**
 * --- Main Navigation ---
 */
header {
    nav.navbar {
        min-height: 36px;
        border: 0 solid transparent;

        &.navbar-default {
            background-color: transparent;
            margin-bottom: 0;

            .langnav {
                ul {
                    list-style-type: none;
                    margin-right: 0;

                    li,
                    li.active {
                        float: left;

                        a {
                            color: #fff;
                            font-size: 14px;
                            text-decoration: none;
                            text-transform: uppercase;

                            &:hover {
                                text-decoration: none;
                                border-bottom: 1px solid #fff;
                            }
                        }

                        span {
                            color: #fff;
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                    }

                    li.active {
                        a {
                            border-bottom: 1px solid #fff;
                        }
                    }
                }
            }

            .navbar-nav {
                margin: 2px 5px 7.25px 0;

                @media (min-width: 768px) {
                    margin: 0;
                }

                > li {
                    &:first-child a {
                        padding-left: 0;
                    }

                    > a {
                        color: #fff;
                        font-size: 16px;
                        padding-left: 0.875rem;
                        padding-right: 0.875rem;
                        padding-top: 4px;
                        padding-bottom: 4px;
                    }
                    border-bottom: 1px solid #fff;

                    @media (min-width: 768px) {
                        border-bottom: 0 solid transparent;
                    }

                    &.active {
                        > a {
                            background-color: transparent;
                            font-weight: 600;

                            &:hover {
                                color: #fff;
                            }
                        }
                    }
                }

                li:first-of-type {
                    border-top: 1px solid #fff;

                    @media (min-width: 768px) {
                        border-top: 0 solid transparent;
                    }
                }
            }

            .navbar-collapse {
                border-color: transparent;
                padding-left: 0;

                @media (max-width: 767px) {
                    .navbar-nav > li a {
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                    }

                    .navbar-nav > li:first-child a {
                        padding-left: 15px;
                    }
                }
            }

            .navbar-toggler {
                float: left;
                margin: 1px 2px 1px 0;
                padding: 0;
                border: 0 solid transparent;

                .fa-mobile {
                    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
                    @extend .fa-times;
                    font-size: 32px;
                    color: #fff;
                }

                &.collapsed {
                    margin: 1px 0 1px 0;

                    .fa-mobile {
                        /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
                        @extend .fa-bars;
                        color: #fff;
                    }
                }

                &:hover,
                &:focus {
                    box-shadow: none;
                    background-color: transparent;
                }
            }

            span.mtext {
                font-size: 14px;
                color: #fff;
                display: inline-block;
                margin-top: 14px;

                @media (min-width: 768px) {
                    display: none;
                }
            }
        }
    }
}

/**
 * --- Breadcrumb ---
 */
nav {
    ol.breadcrumb {
        display: block;
        list-style-type: none;
        margin-bottom: 0;
        padding: 8px 0;

        li {
            display: inline-block;
            font-size: 11px;
            text-transform: uppercase;

            a {
                color: $brand-blue-light;
                text-transform: uppercase;

                &:hover,
                &:focus {
                    color: $brand-blue-light;
                }
            }
        }

        >li + li::before {
            /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
            font-family: FontAwesome;
            content: '\f054';
            color: $brand-blue-light;
            padding: 0 5px;
        }
    }
}

/**
 * --- Sub Navigation ---
 */
.navbox {
    margin-top: 10px;

    ul.sub-nav {
        border-top: 1px solid #ccc;

        >li {
            border-bottom: 1px solid #ccc;

            >a {
                padding: 10px 10px;

                span.fa {
                    margin-top: 5px;
                    float: right;
                }
            }

            &.active {
                a {
                    color: #333;
                    background-color: #f1f1f1;
                }

                span.fa {
                    display: none;
                }
            }

            &:hover {
                background-color: #f1f1f1;

                span.fa {
                    display: none;
                }
            }
        }
    }
}

/**
 * --- Footer Navigation ---
 */
footer {
    .container,
    .container-wide {
        ul.nav-footer {
            list-style-type: none;
            padding-left: 0;

            li {
                float: left;

                a {
                    color: #fff;
                    cursor: pointer;
                }

                span {
                    color: #fff;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }

            @include media-breakpoint-down(sm) {
                display: table;
                width: 100%;

                li {
                    float: none;
                    display: table-cell;
                    width: 33%;
                    text-align: center;

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
