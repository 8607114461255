div[data-form-block-id] {
    .onFormSubmittedFeedback {
        align-items: flex-start !important;
        justify-content: left !important;
        width: auto !important;
        height: auto !important;

        .onFormSubmittedFeedbackInternalContainerStyle {
            padding: 0 !important;
        }

        .onFormSubmittedFeedbackMessage {
            color: #212121 !important;
            background-color: #dff0d8 !important;
            border-color: #3c763d !important;
            padding: 1rem !important;
        }

        .onFormSubmittedFeedbackButtonSuccess {
            display: none !important;
        }
    }

    .onFormSubmittedFeedbackIcon {
        display: none !important;
    }
}

.containerWrapper {
    button.lp-form-button {
        // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
        @extend .btn;
        // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
        @extend .btn-primary;
    }
}
