.video-embed {
    position: relative;
}

.no-consent-info {
    display: none;
}

.no-consent {
    margin-bottom: 1rem;

    .no-consent-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        font-style: italic;
        text-align: center;
        padding: 1rem;
        background-color: #f1f1f1;
        margin: 0;

        a {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
