/**
 * --- Teaser Content Element
 */
.ce-textpicbox {
    background-color: #fff;
    border-bottom-right-radius: 16px;
    -webkit-border-bottom-right-radius: 16px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 305px;

    img.teaser {
        display: block;
        max-width: 100%;
        height: auto;
    }

    h4 {
        margin-top: 8px;
        margin-bottom: 4px;
    }

    .boxcontent {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 5px;

        p > a {
            display: block;
            color: $brand-blue-light;
        }
    }
}

.container-wide-grey {
    padding-bottom: 30px;

    .row {
        /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
        @extend .g-4;
    }

    .frame-type-textpic {
        &.frame-layout-1 {
            @extend .card;  /* stylelint-disable-line */
            @extend .h-100; /* stylelint-disable-line */
            border: 0;
            border-bottom-right-radius: 16px;
            max-width: 305px;
            margin: 0 auto;

            .ce-textpicbox {
                margin-bottom: 0;
            }
        }
    }

    .ce-textpicbox {
        .boxcontent {
            padding-left: 10px;
            padding-right: 10px;

            p > a {
                text-align: right;

                &::after {
                    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                    font-family: FontAwesome;
                    content: ' \f054';
                }
            }
        }
    }
}

/**
 * --- Contact Panel Content Element
 */
.contactpanel {
    padding: 20px;
    background-color: #e6e6e6;
    border-bottom-right-radius: 16px;
    -webkit-border-bottom-right-radius: 16px;
    margin-bottom: 20px;

    .portrait {
        float: left;
        margin-right: 20px;
        margin-bottom: 5px;
    }

    .details {
        float: left;
        margin-top: 20px;

        header {
            h4 {
                margin-bottom: 6px;
            }

            p {
                color: $brand-red-dark;
                text-transform: uppercase;
            }
        }
    }

    @media (max-width: 576px) {
        .portrait {
            float: none;
            margin-right: 0;

            img {
                display: inherit;
                margin: 0 auto;
            }
        }

        .details {
            float: none;
            text-align: center;
        }
    }

    &.gl {
        padding: 0;
        background-color: transparent;
        border-bottom-right-radius: 0;
        -webkit-border-bottom-right-radius: 0;
    }
}

/**
 * --- Sideboard Panel (Layout 1)
 */
.frame-layout-4 {
    margin: 30px 0;
    padding: 10px;
    background-color: $bg-color-grey-box;
    border-bottom-right-radius: 16px;
    -webkit-border-bottom-right-radius: 16px;

    h2,h3,h4 {
        color: $brand-red-dark;
        margin-top: 0;
    }

    p.text-right {
        a::after {
            /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
            font-family: FontAwesome;
            content: ' \f054';
        }
    }
}
