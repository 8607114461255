.frame-type-form_formframework {
    padding: 10px;
    background-color: #e6e6e6;
}

.container .frame-type-form_formframework {
    background-color: #fff;
}

.form-horizontal {
    .form-control {
        border: 1px solid #f1f1f1;
    }

    textarea.xxlarge {
        width: 100%;
        height: 120px;
    }

    .alert.error.help-block {
        font-size: 14px;
        padding: 5px;
    }

    label {
        font-weight: 400;
        text-align: right;

        &.checkbox-section {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 22px;
                width: 22px;
                background-color: #ccc;
                border-radius: 3px;

                &::after {
                    content: '';
                    position: absolute;
                    display: none;
                    left: 8px;
                    top: 3px;
                    width: 7px;
                    height: 12px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            &:hover input ~ .checkmark {
                background-color: #1982af;
            }

            input:checked ~ .checkmark {
                background-color: #1982af;
            }

            input:checked ~ .checkmark::after {
                display: block;
            }
        }
    }

    .inputs-list {
        margin-top: 7px;

        .radio-inline {
            margin-right: 0.75rem;

            input {
                margin-right: 0.1rem;
            }
        }
    }

    .btn-default {
        background-color: #666;
        color: #fff;

        &.btn-add {
            background-color: #1982af;
            color: #fff;
        }
    }

    .btn-toolbar .btn-group {
        float: right;
    }
}
