/**
 * --- News
 */
.news-list-view {
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.news-list-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;

    .article {
        margin: 0 10px 10px;

        time {
            font-size: 13px;
            color: #999;
        }

        h4 {
            margin-top: 0;
            margin-bottom: 8px;

            a {
                color: #4c4c4c;
            }
        }
    }
}

.news-single {
    .teaser-text {
        font-weight: 600;
    }

    .news-img-wrap {
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}

.pagination {
    li {
        /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
        @extend .page-item;

        a {
            /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
            @extend .page-link;
        }
    }
}
